@import "../../Varaibles/varaibles.scss";

.content-layout {
  min-height: 100vh;
  width: 100%;
  @include breakpoint(md) {
    background-color: $bgOffWhite;
  }

  .custom-sider.ant-layout-sider-zero-width {
    display: hidden;
    margin-left: -40px;
  }

  .custom-sider {
    background-color: $bgOffWhite;
    position: fixed;
    height: 100vh;
    width: 280px;
    padding: 14px 10px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    z-index: 30;
    // opacity: 0;

    @include breakpoint(md) {
      padding: 20px 16px;
      height: 100vh;
      overflow-y: auto;
      border-right: none;
      border-radius: none;
    }

    .ant-layout-sider-children {
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 10;

      .sidebar-top {
        .sidebar-cancel-button {
          svg {
            width: 45px;
          }

          @include breakpoint(md) {
            display: none;
          }
        }
        .nsib-logo {
          width: 125px;
          margin-bottom: 20px;
          padding: 0 4px;

          @include breakpoint(md) {
            width: 224px;
            margin-bottom: 40px;
          }
        }
      }

      .sidebar-links {
        // background-color: transparent;
        // border-inline-end: none;
        // display: flex;
        // flex-direction: column;
        // gap: 3px;
        // cursor: pointer;

        color: $linkLightBlack;
        height: 32px;
        transition: all;
        transition-duration: 300ms;
        transition-behavior: ease;
        padding: 4px 10px;
        cursor: pointer;
        font-size: $regularSize;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;

        .svg {
          color: $linkLightBlack;
        }

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &:hover {
          background-color: $bgHoverWhite;
          box-shadow: 0px 0px 0px 1px #12376914;
          box-shadow: 0px 1px 2px 0px #2a3b511f;
          color: $linkLightBlack;

          .svg {
            color: $linkLightBlack;
          }
        }

        &.active {
          background: linear-gradient(0deg, #ffffff, #ffffff),
            linear-gradient(
              180deg,
              rgba(223, 225, 231, 0) 0%,
              rgba(223, 225, 231, 0.05) 100%
            );
          box-shadow: 0px 0px 0px 1px #12376914;
          box-shadow: 0px 1px 2px 0px #2a3b511f;
          color: $activeBlack;

          .svg {
            color: $activeBlack;
          }
        }
        &.child{
          padding-left: 34px;
        }
        .notification {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          .count-icon {
            color: white;
            background: $notificationRed;
            font-weight: 500;
            border-radius: 100%;
            font-size: 11px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .ant-menu {
        background-color: transparent;
        border-inline-end: none;
        display: flex;
        flex-direction: column;
        gap: 3px;

        .ant-menu-submenu-title {
          color: $linkLightBlack;
          height: 32px;
          transition: all;
          transition-duration: 300ms;
          transition-behavior: ease;
          padding: 4px 10px !important;
          cursor: pointer;
          font-size: $regularSize;
          font-weight: 500;

          .svg {
            color: $linkLightBlack;
          }

          span {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          &:hover {
            background-color: $bgHoverWhite;
            box-shadow: 0px 0px 0px 1px #12376914;
            box-shadow: 0px 1px 2px 0px #2a3b511f;
            color: $linkLightBlack;

            .svg {
              color: $linkLightBlack;
            }
          }

          &.ant-menu-item-selected {
            background: linear-gradient(0deg, #ffffff, #ffffff),
              linear-gradient(
                180deg,
                rgba(223, 225, 231, 0) 0%,
                rgba(223, 225, 231, 0.05) 100%
              );
            box-shadow: 0px 0px 0px 1px #12376914;
            box-shadow: 0px 1px 2px 0px #2a3b511f;
            color: $activeBlack;

            .svg {
              color: $activeBlack;
            }
          }
        }

        .ant-menu-item {
          color: $linkLightBlack;
          height: 32px;
          transition: all;
          transition-duration: 300ms;
          transition-behavior: ease;
          padding: 4px 10px !important;
          cursor: pointer;
          font-size: $regularSize;
          font-weight: 500;

          .svg {
            color: $linkLightBlack;
          }

          span {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          &:hover {
            background-color: $bgHoverWhite;
            box-shadow: 0px 0px 0px 1px #12376914;
            box-shadow: 0px 1px 2px 0px #2a3b511f;
          }

          &.ant-menu-item-selected {
            background: linear-gradient(0deg, #ffffff, #ffffff),
              linear-gradient(
                180deg,
                rgba(223, 225, 231, 0) 0%,
                rgba(223, 225, 231, 0.05) 100%
              );
            box-shadow: 0px 0px 0px 1px #12376914;
            box-shadow: 0px 1px 2px 0px #2a3b511f;
            color: $activeBlack;

            .svg {
              color: $activeBlack;
            }
          }
        }
        .notification {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          .count-icon {
            color: white;
            background: $notificationRed;
            font-weight: 500;
            border-radius: 100%;
            font-size: 11px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        // .ant-menu-submenu-title {
        //   color: #001529;

        //   &:hover {
        //     color: #001529;
        //   }
        // }

        .ant-menu-sub {
          background-color: none !important;
        }
      }

      .sidebar-bottom {
        margin-bottom: 50px;
        padding-top: 16px;

        @include breakpoint(md) {
          padding-top: 32px;
        }
        .popover {
          width: 100%;
          margin-top: 16px;
          display: flex;
          gap: 8px;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 10px 6px;
          margin-bottom: 55px;
          border-radius: 8px;
          transition: all;
          transition-duration: 300ms;
          transition-behavior: ease;
          cursor: pointer;

          &:hover {
            background-color: $bgHoverWhite;
          }

          @include breakpoint(md) {
            margin-top: 32px;
            margin-bottom: 0;
          }

          .icon-text {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            overflow: hidden;

            .initials {
              color: #5f38fa;
              display: flex;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              font-size: $regularSize;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background: linear-gradient(
                  0deg,
                  rgba(102, 65, 250, 0.05),
                  rgba(102, 65, 250, 0.05)
                ),
                linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 0.16) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
            }
            .others {
              p:first-child {
                font-weight: 500;
                color: #0d0d0d;
                font-size: $regularSize;
              }
              p:last-child {
                font-size: $smallerSize;
                color: #1a1b1d80;
                text-decoration: none;
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }

  .page-container {
    min-height: 100vh;
    padding-top: 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    position: relative;

    @include breakpoint(md) {
      background-color: $bgOffWhite;
      padding: 10px 10px 10px 285px;
    }

    .mobile-transparent-background {
      position: fixed;
      height: 100vh;
      width: 100vh;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 27%,
        rgba(0, 0, 0, 0.6) 100%
      );
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &.show {
        display: block;
        z-index: 20;
        @include breakpoint(md) {
          display: none;
        }
      }
      &.hide {
        display: none;
        @include breakpoint(md) {
          display: none;
        }
      }

      @include breakpoint(md) {
        display: none;
      }
    }

    .content-container {
      border-radius: 24px;
      background: white;
      width: 95%;
      padding: 0;
      margin: 0 auto;

      @include breakpoint(md) {
        border: 1px solid $borderWhite;
        width: 100%;
      }

      main {
        @include breakpoint(md) {
          padding: 18px 32px;
        }
      }
    }
  }
}
