@import "../../../Varaibles/varaibles.scss";

.detail-row {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 12px 2px;
  width: 100%;
  box-sizing: border-box;

  .row-label {
    color: rgba(28, 31, 29, 0.8);
    font-family: Archivo;
    font-size: 14px;
    font-weight: 500;

    @include breakpoint(md) {
      font-size: 12px;
    }
  }
  .row-content {
    color: var(--Main-black, #0d0d0d);
    font-family: Archivo;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 600;
  }
}
