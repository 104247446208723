@import "../../../Varaibles/varaibles.scss";

.css-13cymwt-control {
  .css-qbdosj-Input {
    padding: 5px;
  }
  background-color: #f0f0f0 !important;
  border-color: transparent !important;
  border-radius: 10px !important;
}

.css-t3ipsp-control:hover {
  border-color: #5f38fa !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #5f38fa !important;
  background-color: #f0f0f0 !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  .css-qbdosj-Input {
    padding: 5px;
  }
}

.custom-input-dropdown {
  border: 2px solid red;
  background-color: #ffeeee;
}