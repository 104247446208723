@import "../../Varaibles/varaibles.scss";

.mobile-filter-container {
  width: 100%;
  font-family: Archivo;

  .ant-segmented {
    background-color: white;
    .ant-segmented-group {
      gap: 8px;
      flex-wrap: wrap;
      background-color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      border: none !important;

      .ant-segmented-item {
        color: #1a1b1d80;
        display: flex;
        height: 32px;
        padding: 6px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 32px;
        border: 1px solid #ebebeb;
        text-transform: capitalize;
      }
      .ant-segmented-item-selected {
        background-color: $deepPurple;
        color: white;
      }
    }
  }

  .option-container {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 30px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .option {
      padding: 10px 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      
      svg {
        width: 20px;
        height: 20px;
        display: none;

        &.custom-arrow{
          display: block;
        }
      }

      &.selected {
        background-color: #fafafa;
        transition: all 300ms ease-in;
        svg {
          display: block;
        }
      }
    }
  }

  .button-container {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
