@import "../../Varaibles/varaibles.scss";

.rs-btn-link {
  color: $deepPurple;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px $deepPurple;
}

.rs-picker-daterange-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.rs-picker-daterange-header .rs-picker-header-date, .desktop-date-range .rs-picker-daterange-header .rs-picker-header-date {
  width: 100%;
  text-align: center;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: $deepPurple;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: $deepPurple;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  background-color: $deepPurple;
}

.rs-picker-toolbar-right .rs-btn {
  background-color: $deepPurple;
}

.rs-calendar-table-cell-in-range:before {
  background-color: $lightPurple;
  box-shadow: inset 0 0 0 1px $lightPurple;
}
