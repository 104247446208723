@import "../../../Varaibles/varaibles.scss";

.bank-generator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Main-black, #0d0d0d);
    font-family: Archivo;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    font-weight: inherit;

    .logo {
      flex-shrink: 0;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #fff;
      border: 1px solid #f7f7f7;
    }
  }