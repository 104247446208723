@import "../../Varaibles/varaibles.scss";

.download-container {
  @include breakpoint(md) {
    padding: 10px;
    width: 100%;
  }

  .popover-item {
    padding: 8px 10px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
  }

  .popover-item:hover {
    background-color: #f0f0f0;
  }

  .popover-text {
    color: var(--Main-black, #0d0d0d);
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}
