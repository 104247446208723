@import "../../Varaibles/varaibles.scss";

.manual-payout-container {
  @include breakpoint(md) {
    padding: 24px 0px;
  }
  label {
    color: rgba(13, 13, 13, 0.5);
    font-family: Archivo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: rgba(106, 106, 106, 0.6);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
  }

  .payout-content {
    @include breakpoint(md) {
      margin-top: 24px;
    }
  }
  .status-content-container {
    &.error,
    &.warning,
    &.success {
      background: white;
    }
    .cancel-button {
      display: none;
    }
    .check-mark {
      margin-top: 0px;
    }
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
      display: flex;
      align-items: end;
      width: fit-content;
      // .ant-checkbox {
      //   // margin-top: -18px;
      //   // span{
      //   // display: none;
      //   // }
        
      //   border: 1px solid red;
      // }
      span:nth-of-type(2){
        display: none;
      }
      .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: $deepPurple;
        &:hover {
          border: 1px solid $deepPurple;
        }
        &:active {
          border: 1px solid $deepPurple;
        }
      }
      // &:hover {
      //   .ant-checkbox-inner {
      //     border: 1px solid $deepPurple;
      //   }
      // }
      // &:active {
      //   .ant-checkbox-inner {
      //     border: 1px solid $deepPurple;
      //   }
      // }
      .ant-checkbox-checked .ant-checkbox-inner {
        border: 1px solid $deepPurple;
      }
      .check-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .main-text {
          color: #0d0d0d;
          font-family: Archivo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .description-text {
          color: #0d0d0d99;
          font-family: Archivo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  
}

.custom-dropdown {
  position: relative;
  .rs-picker-toggle-wrapper,
  .rs-picker-menu {
    z-index: 1050; /* Increase z-index if needed */
  }

  .rs-picker-menu {
    position: absolute; /* Ensure the menu positions relative to the dropdown */
    top: 100%; /* Position it directly below the input */
    left: 0;
    width: auto; /* Adjust width if needed */
    overflow: visible !important; 
  }
}

.rs-table-row-wrapper,
.rs-table-cell-content {
  overflow: visible !important; /* Allow overflow in table cells */
}
