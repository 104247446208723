@import "../../Varaibles/varaibles.scss";

.request-detail-container {
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  // overflow-y: scroll;
  // /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  // -ms-overflow-style: none; /* Internet Explorer and Edge */
  // scrollbar-width: none; /* Firefox */

  // /* Hide the scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.payout-container {
  padding: 14px;
  height: 100%;
  box-sizing: border-box;

  // overflow-y: scroll;
  // /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  // -ms-overflow-style: none !important; /* Internet Explorer and Edge */
  // scrollbar-width: none !important; /* Firefox */

  // /* Hide the scrollbar for Chrome, Safari and Opera */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }

  @include breakpoint(md) {
    padding: 24px;
  }
  label {
    color: rgba(13, 13, 13, 0.5);
    font-family: Archivo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: rgba(106, 106, 106, 0.6);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
  }

  .payout-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
    gap: 32px;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .x-icon {
        display: block;
      }
      .arrow-icon {
        display: hidden;
      }
    }
    .cancel-button {
      margin: 0;
      // @include breakpoint(md) {
      //   margin-top: 12px;
      //   margin-right: 24px;
      // }
    }

    div {
      display: none;
      align-items: center;
      gap: 10px;
      @include breakpoint(md) {
        display: flex;
      }
      h2 {
        color: var(--Main-black, #0d0d0d);
        font-family: Archivo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .status-container {
        color: #36394a;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        display: inline-flex;
        height: 24px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 22px;
        background: #fff;
        box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);

        .indicator {
          padding: 4px;
          margin-left: 8px;
          border-radius: 50%;

          &.success {
            background-color: #27a551;
          }
          &.pending {
            background-color: #dd8304;
          }
          &.failed {
            background-color: Red;
          }
        }
      }
    }
    .top-request-button{
      display: flex;
      @include breakpoint(md) {
        display: none;
      }
    }
  }
  .payout-detail {
    margin-top: 25px;
    height: fit-content;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    // overflow-y: scroll;

    @include breakpoint(md) {
      margin-top: 51px;

      /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
      -ms-overflow-style: none; /* Internet Explorer and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide the scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .top-container {
      margin-top: 35px;
      @include breakpoint(md) {
        margin-top: 0;
      }
      .request-image-text {
        font-family: Archivo;
        .avatarPlaceholderText{
          p{
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .detail-intro {
      h2 {
        color: var(--Main-black, #0d0d0d);
        font-feature-settings: "cv10" on, "ss01" on, "liga" off, "calt" off;
        font-family: Archivo;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
        text-wrap: wrap;
        box-sizing: border-box;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;

        @include breakpoint(md) {
          color: #1a1b1d;
          font-size: 30px;
        }
        
      }
      .mobile-status {
        display: flex;
        float: right;
        font-size: 14px;
        font-weight: 500;
        margin: 2px 0;

        @include breakpoint(md) {
          display: none;
        }
      }
      .transaction-id-text {
        display: flex;
        align-items: center;
        color: #1a1b1d;
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 12px;
        gap: 4px;

        span {
          color: #1a1b1db2;

          &.circle {
            width: 5px;
            height: 5px;
            background: #1a1b1db2;
            border-radius: 50%;
          }
        }

        @include breakpoint(md) {
          font-size: 16px;
          margin-top: 0px;
        }
      }
    }
    .detail-info {
      margin-top: 30px;
    }
  }
}
