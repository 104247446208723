@import '../../Varaibles/varaibles.scss';

@keyframes vertical-marquee {
    0% { transform: translateY(0%); }    /* Start from top */
    100% { transform: translateY(50%); }  /* Move to halfway */
  }
  
  .animate-vertical-marquee {
    animation: vertical-marquee 7s linear infinite;
    white-space: nowrap;
    animation-delay: 0s;
  }

@keyframes vertical-marquee-up {
    0% { transform: translateY(100%); } /* Start from bottom */
    100% { transform: translateY(0%); } /* End at top */
  }
  
  
  .animate-vertical-marquee-up {
    animation: vertical-marquee-up 10s linear infinite;
    // white-space: nowrap;
    animation-delay: 0s; 
  }

.landing-container{
    // background-color: $bgOffWhite;
    height: 100vh;
    width: 100%;

    .landing-border{
        border-radius: 40px 0px 0px 40px;
    }

    .arrow-container{
        background-color: $borderWhite;
    }

  .up-background{
background: linear-gradient(136deg, rgba(250, 250, 250, 0.00) 11.93%, #FAFAFA 64.84%);
    filter: blur(0px);;
  
  }

  .down-background{
    background: linear-gradient(134deg, rgba(250, 250, 250, 0.00) 11.04%, #FAFAFA 57.03%);
    filter: blur(0px);
  }

  .radial{
    background: radient-gradient(circle, rgba(250, 250, 250, 0.8), transparent)};
}

