// common styles shd be here
@import "../Varaibles/varaibles.scss";

.page-wrapper {
  // put
}

.main-table-wrapper {
  overflow-x: scroll;
  /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.main-table-wrapper-bulk {
  // overflow-x: scroll;
  // overflow-x: scroll; /* Allows horizontal scrolling */
overflow-y: visible !important; /* 
/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
-ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}


.tax-breakdown {
  margin-top: 20px;
  border-top: 1px dashed #ededed;
  border-bottom: 1px dashed #ededed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
  
}
.tax-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: #0d0d0d99;
  font-size: 14px;
  font-family: Archivo;
  font-weight: 500 !important;
  line-height: normal;

  &.amount-payable {
    color: #0d0d0d;
    margin-top: 15px;
  }
}

.table-container-wrapper {
  // padding: 24px;
  h3 {
    color: var(--Black, #0d0d0d);
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }

  @include breakpoint(lg) {
    background: white;
    border-radius: 12px;
    // padding: 12px;
  }
}

.filter-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;

  .mobile-status-filter {
    display: flex;
    height: 32px;
    padding: 6px 10px;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #ebebeb;
    @include breakpointasc(lg) {
      display: none;
    }
  }

  .large-status-filter {
    display: block;

    @include breakpointasc(lg) {
      display: none;
    }
  }

  .search-container {
    // width: 400px;
    // max-width: 400px;

    @include breakpointasc(lg) {
      width: 100%;
      position: relative;
    }
  }
}
