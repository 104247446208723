.status-setter {
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  display: inline-flex;
  height: 24px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
