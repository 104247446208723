@import "../../Varaibles/varaibles.scss";

.custom-daterange-bottomsheet {
  width: 100%;
  margin-top: 30px;
  .embedded-date-range-picker {
    position: relative;
    display: inline-block;
    width: 100%;

    .rs-picker-block {
      display: none;
    }

    .rs-picker-popup {
      position: relative !important;
      top: 0;
      left: 0;
      background-color: none !important;
      box-shadow: none !important;

      .rs-calendar:first-child {
        border-right: none !important;
      }

      .rs-stack {
        width: 100%;

        .rs-stack-item {
          width: 100%;

          .rs-picker-toolbar-right{
            float: right;
            width: fit-content !important;
            display: flex;
            align-items: end;
          }
        }
      }

      .rs-picker-daterange-header.rs-picker-tab-active-end:after,
      .rs-picker-daterange-header.rs-picker-tab-active-start:after {
        border-bottom: 1px solid $deepPurple;
      }
    }
    .rs-btn-icon.rs-btn-xs {
      border-radius: 7.508px;
      background: var(--White, #fff);
      box-shadow: 0px 1.251px 1.251px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1.251px rgba(70, 79, 96, 0.16);

        &:active{
            background-color: $deepPurple;
            color: white;
        }
    }
    // .rs-picker-toolbar {
    //   display: none !important;
    // }
    .rs-calendar-month-dropdown-list {
      width: 100%;
    }
    .rs-calendar-month-dropdown-year-active {
      color: $deepPurple;
    }
    .rs-calendar-month-dropdown-cell-active
      .rs-calendar-month-dropdown-cell-content {
      background-color: $deepPurple;
    }
  }
}
