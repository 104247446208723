@import "../../Varaibles/varaibles.scss";

.side-drawer {
  overflow-x: scroll;
  /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  .cancel-button {
    margin-top: 10px;
    margin-left: 12px;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(223, 225, 231, 0) 0%,
      rgba(223, 225, 231, 0.05) 100%
    );
    box-shadow: 0px 1px 2px 0px rgba(42, 59, 81, 0.12),
      0px 0px 0px 1px rgba(18, 55, 105, 0.08);
    background-color: #f9f9f9;
    display: flex;
    width: 32px;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    color: $activeBlack;

    @include breakpoint(md) {
      float: right;
      // margin-top: 12px;
      // margin-right: 24px;
    }

    @include breakpoint(md) {
      padding: 0;
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }

    &:hover {
      background-color: $bgHoverWhite;
    }
  }
}
