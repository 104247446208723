@import "../../../Varaibles/varaibles.scss";

.label {
  color: $darkCharcoal;
}

/* global.css or any imported CSS file */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none; /* Standard syntax */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="password"]::-ms-reveal {
  display: none;
}

.hide-password {
  display: none;
}

.show-password {
  display: block;
}
