@import "../../Varaibles/varaibles.scss";

.custom-button {
  border-radius: 8px;
  background: $deepPurple;
  box-shadow: 0px 1px 2px 0px rgba(38, 19, 114, 0.4),
    0px 0px 0px 1px rgba(64, 19, 244, 0.76);
  color: white;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: flex; /* Added for flex layout */
  align-items: center; /* Center align items */
  gap: 8px;
  justify-content: center; /* Center items horizontally */
  position: relative;
  text-align: center;

  &.mobile-filter-button {
    border-radius: 32px;
  }

  .hide-mobile-text {
    display: none;

    @include breakpoint(md) {
      display: flex;
    }
  }

  &:hover {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.26) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      #5f38fa;
    box-shadow: 0px 2px 4px 0px rgba(38, 19, 114, 0.5),
      0px 0px 0px 2px rgba(64, 19, 244, 0.86);
  }

  &:focus {
    outline: none;
    box-shadow: 0px 2px 4px 0px rgba(38, 19, 114, 0.5),
      0px 0px 0px 2px rgba(64, 19, 244, 0.86);
  }

  &:active {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #5f38fa;
    box-shadow: 0px 1px 2px 0px rgba(38, 19, 114, 0.4),
      0px 0px 0px 1px rgba(64, 19, 244, 0.76);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .button-icon {
    display: flex;
    align-items: center;

    &.hide-desktop-icon {
      display: flex;

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}
