@import "../../Varaibles/varaibles.scss";

.support-document-component {
  label {
    color: var(--Medium-contrast, #6a6a6a);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.18px;

    span {
      color: rgba(106, 106, 106, 0.6);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
  }

  &:hover {
    .image-upload-block {
      background: #f0f0f0;

      button {
        &:hover {
          background: linear-gradient(
            180deg,
            rgba(223, 225, 231, 0) 0%,
            rgba(223, 225, 231, 0.4) 100%
          );
          box-shadow: 0px 2px 4px 0px rgba(223, 225, 231, 0),
            0px 0px 0px 2px rgba(223, 225, 231, 0.4);
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .supporting-document-input {
    height: 0px;
    width: 0px;
    opacity: -0.1;
    display: none;
  }

  .image-upload-block {
    display: flex;
    width: 100%;
    height: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px dashed #f0f0f0;
    background: #fafafa;
    margin-top: 10px;
    position: relative;
    transition: all 300ms ease-in;
    cursor: pointer;
  }
  .files-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
  }
}
