@import "../../Varaibles/varaibles.scss";

.ant-drawer {
  font-family: Archivo;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  .ant-drawer-content-wrapper {
    height: fit-content !important;
    background-color: rgba(0, 0, 0, 0.45) !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    .ant-drawer-content {
      //   background-color: #0d0d0d !important;
      border-top-left-radius: 30px !important;
      border-top-right-radius: 30px !important;
      .ant-drawer-body {
        .bottom-sheet-top {
          display: flex;
          justify-content: space-between;
          gap: 6px;
          align-items: center;
          margin-bottom: 5px;

          p {
            color: var(--Main-black, #0d0d0d);
            font-family: Archivo;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .bottom-sheet-cancel-button {
            float: right;
            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .ant-drawer-content {
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
      }
    }
  }
}
