.vendor-name {
  display: flex;
  align-items: center;
  gap: 8px;

  .initials-container {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    color: #5f38fa;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: linear-gradient(
        0deg,
        rgba(102, 65, 250, 0.05),
        rgba(102, 65, 250, 0.05)
      ),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.16) 0%,
        rgba(255, 255, 255, 0) 100%
      );
  }
}
