@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fontsource/archivo";
@import './styles/common.scss';


body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.label {
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.18px;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
