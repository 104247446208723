.bulk-summary-page {
  .status-content-container {
    &.error,
    &.warning,
    &.success {
      background: white;
    }
    .cancel-button {
      display: none;
    }
    .check-mark {
      margin-top: 0px;
    }
  }
}
