@import "../../../Varaibles/varaibles.scss";

.file-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px dashed #f0f0f0;
  background: #fafafa;
  box-sizing: border-box;

  .text-part {
    display: flex;
    gap: 16px;
    align-items: center;
    box-sizing: border-box;

    .doc-icon {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      background: rgba(108, 72, 250, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        fill: $deepPurple;
      }
    }

    .main-text {
      color: var(--Main-black, #0d0d0d);
      font-feature-settings: "cv10" on, "ss01" on, "liga" off, "calt" off;
      font-family: Archivo;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoint(md) {
        max-width: 250px;
      }
    }
    .desc-text {
      height: fit-content;
      color: rgba(13, 13, 13, 0.3);
      font-feature-settings: "cv10" on, "ss01" on, "liga" off, "calt" off;
      font-family: Archivo;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      display: flex;
      gap: 6px;
      align-items: center;
      margin-top: 0px;

      .dot {
        width: 3px;
        height: 3px;
        background-color: rgba(13, 13, 13, 0.3);
      }
    }
  }
}
