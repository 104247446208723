@import "../../Varaibles/varaibles.scss";

.dashboard-header {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 24px;
  font-family: Archivo;


  @include breakpoint(md) {
    border-bottom: 1px solid $borderWhite;
    padding: 18px 32px;
    margin-bottom: 0;
  }

  .mobile-content-container {
    display: flex;
    gap: 8px;

    @include breakpoint(md) {
      display: none;
    }

    .sidebar-hamburger-button {
      @include breakpoint(md) {
        display: none;
      }
    }

    .mobile-nsib-logo {
      width: 110px;
      object-fit: contain;
      object-position: center;

      @include breakpoint(md) {
        display: none;
      }
    }
  }

  .main-content {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    .path-holder {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #0d0d0db2;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        svg {
          color: #0d0d0db2;
        }
      }
      &.hide {
        display: none;
        overflow: hidden;
        @include breakpoint(md) {
          display: flex;
        }
      }
    }
    .page-button-holder {
      display: flex;
      align-items: center;
      gap: 6px;

      @include breakpoint(md) {
        gap: 12px;
      }
    }

    h1{
      line-height: normal;
    }
  }
}
