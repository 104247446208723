@import "../../Varaibles/varaibles.scss";


.status-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  text-align: center;
  padding: 10px;
  // background: linear-gradient(180deg, #FF9993 -11.45%, rgba(255, 255, 255, 0.00) 10%);
}

.status-content-container.error {
  background: linear-gradient(180deg, #ff9993 -11.45%, rgba(255, 255, 255, 0) 20%);
}
.status-content-container.warning {
  background: linear-gradient(180deg, #ffba83 -11.45%, rgba(255, 255, 255, 0) 20%);
}
.status-content-container.success {
  background: "white";
}

.check-mark {
  width: 124px;
  height: 124px;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  .check-svg {
    position: relative;
    z-index: 10;
  }

  .rotating-background {
    width: 125px;
    height: 125px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.text-container {
  margin-top: 32px;
  margin-bottom: 35px;
  
  @include breakpoint(md) {
    width: 90%;
  }

  h2 {
    color: '#0D0D0D';
    font-family: "Archivo";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-style: normal;
    }
  }
  p {
    color: var(--Body-text, rgba(13, 13, 13, 0.6));
    text-align: center;
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;

    @include breakpoint(md) {
      font-size: 16px;
    //   width: 85%;
      margin: 0 auto;
    }
  }
  .cancel-button {
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(223, 225, 231, 0) 0%,
      rgba(223, 225, 231, 0.05) 100%
    );
    box-shadow: 0px 1px 2px 0px rgba(42, 59, 81, 0.12),
      0px 0px 0px 1px rgba(18, 55, 105, 0.08);
    background-color: #f9f9f9;
    display: flex;
    width: 32px;
    justify-content: center;
    align-items: center;
    color: $activeBlack;

    .x-icon {
      display: hidden;

      @include breakpoint(md) {
        display: block;
        stroke-width: 10px;
        stroke: black;
        height: 14px;
      }
    }
    .arrow-icon {
      display: block;
      stroke-width: 1px !important;
      stroke: black;
      width: 14px;
      @include breakpoint(md) {
        display: hidden;
      }
    }

    @include breakpoint(md) {
      padding: 0;
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }

    &:hover {
      background-color: $bgHoverWhite;
    }
  }
}
