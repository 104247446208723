@import "../../Varaibles/varaibles.scss";

.bulk-payout-review-container {
  padding: 14px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    padding: 24px 0px;
  }
  label {
    color: rgba(13, 13, 13, 0.5);
    font-family: Archivo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: rgba(106, 106, 106, 0.6);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
  }

  .sidedrawer-header {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    flex-direction: column-reverse;
    @include breakpoint(md) {
    flex-direction: row;
      align-items: center;
      padding: 24px;
    }

    h2 {
      color: var(--Main-black, #0d0d0d);
      font-family: Archivo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 83.333% */
    }
    button {
        margin: 0;
        margin-bottom: 25px;
      @include breakpoint(md) {
        margin: 0;
      }
    }
  }
  .payout-content {
    margin-top: 15px;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: visible;
    /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide the scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint(md) {
      margin-top: 24px;
    }
    .tax-container {
      .loading-tax-text {
        margin-top: 10px;
      }
      .ant-checkbox-group {
        &.tax-selection {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 14px;

          .ant-checkbox-group-item {
            display: flex;
            align-items: end;
            width: fit-content;
            .ant-checkbox {
              margin-top: -18px;
            }
            .ant-checkbox-checked:not(.ant-checkbox-disabled)
              .ant-checkbox-inner {
              background-color: $deepPurple;
              &:hover {
                border: 1px solid $deepPurple;
              }
              &:active {
                border: 1px solid $deepPurple;
              }
            }
            // &:hover {
            //   .ant-checkbox-inner {
            //     border: 1px solid $deepPurple;
            //   }
            // }
            // &:active {
            //   .ant-checkbox-inner {
            //     border: 1px solid $deepPurple;
            //   }
            // }
            .ant-checkbox-checked .ant-checkbox-inner {
              border: 1px solid $deepPurple;
            }
            .check-item {
              display: flex;
              flex-direction: column;
              gap: 4px;
              .main-text {
                color: #0d0d0d;
                font-family: Archivo;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .description-text {
                color: #0d0d0d99;
                font-family: Archivo;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
      .tax-breakdown {
        margin-top: 20px;
        border-top: 1px dashed #ededed;
        border-bottom: 1px dashed #ededed;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px 0;
      }
      .tax-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        color: #0d0d0d99;
        font-size: 14px;
        line-height: normal;
        font-family: Archivo;
        
        &.amount-payable {
          color: #0d0d0d;
          margin-top: 15px;
        }
      }
    }

    .payout-form-control {
      display: flex;
      gap: 12px;
      justify-content: end;
      padding-bottom: 25px;
      @include breakpoint(md) {
        padding-bottom: 15px;
      }
    }
  }
}
