@import "../../Varaibles/varaibles.scss";

.payout-table {
  width: 100%;
  table-layout: auto;
  //   font-family: Archivo;

  .large-cols-group {
    display: none;

    @include breakpoint(md) {
      display: contents;
    }
  }
  .mobile-cols-group {
    display: contents;
    @include breakpoint(md) {
      display: none;
    }
  }

  .table-header-container {
    padding: 10px;
    // display: none;
    display: contents;
    text-wrap: nowrap;

    // @include breakpoint(md) {
    //   display: contents;
    // }

    tr {
      height: 40px;
      th {
        color: rgba(26, 27, 29, 0.6);

        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0px 4px;
        text-align: left;
        padding: 10px 16px;
        background: #fafafa;
      }
    }
  }

  .table-body-container {
    width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
    &:hover{
      background-color: #f4f4f4 !important;
      transition: all 300ms;
    }

    

    td {
      padding: 14px 0px;
      color: #1a1b1d;
      //   font-family: Archivo;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      text-wrap: nowrap;
      border-bottom: 1px solid #f0f0f0;
        padding: 14px 16px;

      // @include breakpoint(md) {
      //   border-bottom: 1px solid #f0f0f0;
      //   padding: 14px 16px;
      // }

      &.vendor-name {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .additional-details {
        color: rgba(13, 13, 13, 0.4);
        font-family: Archivo;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .initials-container {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        color: #5f38fa;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: linear-gradient(
            0deg,
            rgba(102, 65, 250, 0.05),
            rgba(102, 65, 250, 0.05)
          ),
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.16) 0%,
            rgba(255, 255, 255, 0) 100%
          );
      }

      .status-container {
        color: #36394a;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        display: inline-flex;
        height: 24px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 22px;
        background: #fff;
        box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);

        .indicator {
          padding: 4px;
          margin-left: 8px;
          border-radius: 50%;

          &.success{
            background-color: #27a551;
          }
          &.pending{
            background-color: #DD8304;
          }
          &.failed{
            background-color: Red;
          }
        }
      }
    }
  }
  .large-table-body {
    .no-data-container{
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #afafaf;
      text-align: center;
      border-bottom: none;
    }
  }
}
