.initiate-card {
  .support-document-component {
    width: 100%;
    .image-upload-block {
      height: fit-content;
      border: none;
      background: none;
    }
  }
}
