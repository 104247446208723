@import "../../Varaibles/varaibles.scss";

.custom-outline-button {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(42, 59, 81, 0.12),
    0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  color: $outlineBlack;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: flex; /* Added for flex layout */
  align-items: center; /* Center align items */
  gap: 8px;
  justify-content: center; /* Center items horizontally */
  position: relative;
  text-align: center;

  &.mobile-filter-button{
    border-radius: 32px;
  }

  .hide-mobile-text {
    display: none;

    @include breakpoint(md) {
      display: flex;
    }
  }

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(223, 225, 231, 0) 0%,
      rgba(223, 225, 231, 0.4) 100%
    );
    box-shadow: 0px 2px 4px 0px rgba(223, 225, 231, 0),
      0px 0px 0px 2px rgba(223, 225, 231, 0.4);
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 1px 2px 0px rgba(223, 225, 231, 0),
      0px 0px 0px 1px rgba(223, 225, 231, 0.4);
  }

  .button-icon {
    display: flex;
    align-items: center;

    &.hide-desktop-icon {
      display: flex;

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}
